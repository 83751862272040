@import 'bootstrap/dist/css/bootstrap.min.css';

@font-face {
    font-family: Lato;
    src: url("./assets/Lato-Regular.ttf");
}

@font-face {
    font-family: NunitoSans;
    src: url("./assets/NunitoSans-Bold.ttf");
}



body,html {
    background-color: #F3F1ED;
    height: 100%;
    margin: 0;
}

/* Support Page Styles */
.support-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Lato', sans-serif;
    color: #000;
    line-height: 1.6;
  }
  
  .support-page h1,
  .support-page h2 {
    font-family: 'NunitoSans', sans-serif;
    color: #377D8A;
  }
  
  .support-page h1 {
    font-size: 2.5em;
    text-align: center;
  }
  
  .support-page h2 {
    font-size: 1.8em;
    margin-top: 30px;
  }
  
  .support-page section {
    margin-bottom: 30px;
  }
  
  .support-page form {
    display: flex;
    flex-direction: column;
  }
  
  .support-page input,
  .support-page textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Lato', sans-serif;
  }
  
  .support-page textarea {
    height: 100px;
  }
  
  .support-page button {
    background-color: #377D8A;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'NunitoSans', sans-serif;
    font-weight: bold;
  }
  
  .support-page button:hover {
    background-color: #2a6069;
  }
  
  .support-page a {
    color: #377D8A;
    text-decoration: none;
  }
  
  .support-page a:hover {
    text-decoration: underline;
  }
  
  .support-page .terms-of-service ol {
    padding-left: 20px;
  }
  
  .support-page .terms-of-service li {
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .support-page {
      padding: 10px;
    }
    
    .support-page h1 {
      font-size: 2em;
    }
    
    .support-page h2 {
      font-size: 1.5em;
    }
  }



